import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({ name, title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({ seo: defaultSeo }) => {
      const _name = name ? `${name} | ` : ''
      const seo = {
        title: title || _name + defaultSeo.title,
        description: description || defaultSeo.description,
        image: `${defaultSeo.canonurl}${image || defaultSeo.image}`,
        url: `${defaultSeo.canonurl}${pathname || '/'}`,
      }

      return (
        <>
          <Helmet
            title={seo.title}
            link={[
              { rel: 'shortcut icon', type: 'image/png', href: `/favicon.png` },
            ]}
          >
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <html lang="en" />
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO

const query = graphql`
  query {
    seo {
      canonurl
      title
      description
      image
    }
  }
`
