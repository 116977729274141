import React from 'react'
import SEO from './seo'
import { ZoneManager } from 'use-zone-breakpoints'
import { breakpoints } from './utils'

import './layout.css'

const Layout = ({ children, ...rest }) => {
  return (
    <>
      <SEO {...rest} />
      <ZoneManager breakpoints={breakpoints}>{children}</ZoneManager>
    </>
  )
}

export default Layout
